<template>
  <div>
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div v-if="showInsetEnrollment">
      <div class="client-profile-title">
        Client Profile
        <i
          class="fa fa-info-circle opp-info"
          data-toggle="tooltip"
          data-placement="top"
          title="This is the client profile page. Here you can view, edit client information and send Letter Of Intent (LOI) to clients."
        />
      </div>
      <v-row class="pt-3">
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <b-alert v-model="showSuccessAlert" variant="success" dismissible>
                {{ message }}
              </b-alert>
              <div v-if="showLoading" class="text-center py-3">
                <v-progress-circular
                  :size="70"
                  :width="8"
                  color="green"
                  indeterminate
                ></v-progress-circular>
                <div>
                  <h4>Please wait while we process your request...</h4>
                </div>
              </div>
              <div class="sidebar-error-message" v-else-if="hasError">
                <p class="text-danger">Something went wrong...</p>
                <p>
                  The request could not be processed ({{ loadingErrorMsg }}).
                </p>
                <v-btn @click="resetLoadingState()">close</v-btn>
              </div>

              <div v-else-if="clientProfile.length > 0">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Client ID</th>
                      <th>Client Name</th>
                      <th>Point Of Contact (POC)</th>
                      <th>Phone Number</th>
                      <th>LOI Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in clientProfile" :key="record.id">
                      <td>{{ record.client.id }}</td>
                      <td>{{ record.client.name }}</td>
                      <td>{{ record.poc_email }}</td>
                      <td>{{ formatPhoneNumber(record.phone_number) }}</td>
                      <td>
                        <span
                          :class="{
                            'status-needs-signature':
                              record.loi_status === 'Needs Signature',
                            'status-needs-loi':
                              record.loi_status === 'Needs LOI',
                            'status-signed': record.loi_status === 'Signed',
                          }"
                        >
                          {{ record.loi_status }}
                        </span>
                      </td>
                      <td>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              class="mr-2"
                              color="blue"
                              @click="openEditClientProfile(record)"
                            >
                              mdi-pencil
                            </v-icon>
                          </template>
                          <span>Edit Profile</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              class="mr-2"
                              :class="{
                                'icon-disabled': !needsSignature(record),
                              }"
                              color="blue-grey"
                              @click="
                                needsSignature(record) &&
                                  openFileUploadModal(record)
                              "
                            >
                              mdi-upload
                            </v-icon>
                          </template>
                          <span>Upload File</span>
                        </v-tooltip>

                        <v-tooltip v-if="enabled" bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              class="mr-2"
                              :class="{
                                'icon-disabled': !canSendESign(record),
                              }"
                              color="red"
                              @click="
                                canSendESign(record) &&
                                  sendLOIForSignature(record)
                              "
                            >
                              mdi-pen-lock
                            </v-icon>
                          </template>
                          <span>Send LOI for E-Signature</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              class="mr-2"
                              :class="{
                                'icon-disabled': !canDownloadLOI(record),
                              }"
                              color="green"
                              @click="
                                canDownloadLOI(record) &&
                                  downloadLOI(record.loi_pdf)
                              "
                            >
                              mdi-download
                            </v-icon>
                          </template>
                          <span>Download LOI</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              medium
                              class="mr-2"
                              :class="{
                                'icon-disabled': !canGenerateLOI(record),
                              }"
                              color="orange"
                              @click="canGenerateLOI(record) && openLOI(record)"
                            >
                              mdi-file-plus
                            </v-icon>
                          </template>
                          <span>Generate LOI</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>

              <div v-else class="text-center py-3">
                <h1>No Client Found For {{ org.name }}</h1>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <EditClientProfileModal
        v-if="openEditClientProfileModal"
        :selectedClientProfile="selectedClient"
        @close-modal="openEditClientProfileModal = false"
        @save-client-profile="saveClientProfile"
      />

      <LetterOfIntentModal
        v-if="openLOIModal"
        :selectedClient="selectedClient"
        :org="org"
        @close-modal="openLOIModal = false"
        @generate-loi="generateLOI"
      />

      <template>
        <v-dialog v-model="uploadFileModal" max-width="600">
          <v-card>
            <v-card-title class="headline">
              {{
                selectedClient
                  ? `Upload Signed LOI for ${selectedClient.client.name}`
                  : "Upload Signed LOI"
              }}
            </v-card-title>
            <v-card-text>
              <p>Only PDF file are accepted.</p>
            </v-card-text>
            <v-card-text>
              <v-file-input
                v-model="file"
                accept="application/pdf"
                label="Select a file"
                outlined
              ></v-file-input>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="uploadFileModal = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="uploadLOI"
                :disabled="!file"
              >
                Upload
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
    </div>
  </div>
</template>

<script>
import JSZip from "jszip"
import _ from "lodash"
import moment from "moment"
import { mapState, mapActions, mapGetters } from "vuex"
import { Uploads, Fields } from "@/store/modules"
import CarbonFlowSteppers from "@/views/CarbonFlowSteppers"
import Permissions from "@/components/permissions/Permissions"
import EditClientProfileModal from "../components/modals/EditClientProfileModal.vue"
import LetterOfIntentModal from "../components/modals/LetterOfIntentModal.vue"
import FieldsAPI from "@/api/FieldsAPI"
import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic"
export default {
  name: "EnrollFieldsView",
  components: {
    CarbonFlowSteppers,
    Permissions,
    EditClientProfileModal,
    LetterOfIntentModal,
  },

  data() {
    return {
      hasError: false,
      showSuccessAlert: false,
      message: "",
      success: false,
      showLoading: false,
      openLOIModal: false,
      clientProfile: [],
      selectedClient: null,
      openEditClientProfileModal: false,
      uploadFileModal: false,
      file: null,
      fetchIntervalID: null,
    }
  },

  computed: {
    ...mapState({
      showSustainability: state =>
        state.Organization.organization.showSustainability,
      showInsetEnrollment: state =>
        state.Organization.organization.showInsetEnrollment,
      user: state => state.User.user,
      org: state => state.Organization.organization,
      featureFlags: state => state.Fields.featureFlags,
    }),
    enabled() {
      return this.featureFlags["e_sign"] === true
    },
  },

  methods: {
    ...mapActions({
      createUpload: Uploads.Actions.create,
    }),
    needsSignature(record) {
      return record.loi_status === "Needs Signature"
    },
    canSendESign(record) {
      return (
        record.loi_status === "Needs Signature" ||
        record.loi_status === "Created"
      )
    },
    canDownloadLOI(record) {
      return !!record.loi_pdf
    },
    canGenerateLOI(record) {
      return record.loi_status === "Needs LOI"
    },
    formatPhoneNumber(phoneNumber) {
      if (!phoneNumber) return ""
      const cleaned = phoneNumber.replace(/\D/g, "")
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return `(${match[1]})-${match[2]}-${match[3]}`
      }
      return phoneNumber
    },
    async fetchClientProfile() {
      this.showLoading = true
      await FieldsAPI.getClientProfile().then(({ data }) => {
        this.clientProfile = data
        this.showLoading = false
      })
    },
    openEditClientProfile(record) {
      this.selectedClient = record
      this.openEditClientProfileModal = true
    },
    async saveClientProfile(payload) {
      this.openEditClientProfileModal = false
      await FieldsAPI.saveClientProfile(payload).then(() => {
        this.fetchClientProfile()
      })
    },
    openLOI(record) {
      this.selectedClient = record
      this.openLOIModal = true
    },
    async generateLOI(payload) {
      this.openLOIModal = false
      this.showLoading = true
      await FieldsAPI.generateLOI(payload).then(() => {
        this.fetchClientProfile()
        this.showLoading = false
        this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been generated successfully.`
        this.showSuccessAlert = true
        setTimeout(() => {
          this.showSuccessAlert = false
        }, 10000)
      })
    },
    openFileUploadModal(record) {
      this.selectedClient = record
      this.uploadFileModal = true
    },
    downloadLOI(url) {
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "Letter_Of_Intent.pdf")
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    sendLOIForSignature(record) {
      this.showLoading = true
      const payload = {
        client_profile_id: record.id,
      }
      FieldsAPI.sendLOIForSignature(payload)
        .then(() => {
          this.fetchClientProfile()
          this.showLoading = false
          this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been sent for signature.`
          this.showSuccessAlert = true
          setTimeout(() => {
            this.showSuccessAlert = false
          }, 10000)
        })
        .catch(error => {
          console.error(error)
          this.showLoading = false
          this.loadingErrorMsg = error.response.data
          this.hasError = true
        })
    },
    async uploadLOI() {
      this.showLoading = true
      const formData = new FormData()
      formData.append("file", this.file)
      formData.append("client_profile_id", this.selectedClient.id)

      if (!this.file) {
        this.showLoading = false
        this.loadingErrorMsg = "Please select a file to upload."
        this.hasError = true
        return
      }

      const uploadSpec = {
        datasetType: "enrollment-loi",
        orgId: this.org.id,
        specs: {
          clientProfileId: this.selectedClient.id,
        },
      }

      const zip = new JSZip()
      zip.file(this.file.name, await this.file.arrayBuffer())
      const result = await zip.generateAsync({ type: "blob" })
      const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
      const newZipName = `enrollment-loi-${uploadSpec.specs.clientProfileId}-${datetimeStr}.zip`
      uploadSpec["file"] = new File([result], newZipName)

      this.createUpload(uploadSpec)
        .then(_ => {
          this.uploadFileModal = false
          new Promise(resolve => setTimeout(() => resolve(), 500)).then(() => {
            this.showLoading = false
            this.message = `Letter of Intent for ${this.selectedClient.client.name} (${this.selectedClient.client.id}) has been uploaded successfully.`
            this.showSuccessAlert = true
            setTimeout(() => {
              this.showSuccessAlert = false
            }, 10000)
          })
        })
        .catch(error => {
          this.showLoading = false
          this.loadingErrorMsg = error.response.data
          this.hasError = true
        })
    },
    resetLoadingState() {
      this.hasError = false
      this.loadingErrorMsg = ""
      this.fetchClientProfile()
    },
  },

  mounted() {
    this.fetchClientProfile()
    this.fetchIntervalID = setIntervalAsync(this.fetchClientProfile, 30000)
  },
  destroyed() {
    if (this.fetchIntervalID) clearIntervalAsync(this.fetchIntervalID)
  },
}
</script>

<style scoped>
.icon-check {
  color: green;
  font-size: 1.5em;
}

.icon-check::before {
  content: "\2714";
}

.icon-x {
  color: red;
  font-size: 1.5em;
}

.icon-x::before {
  content: "\2716";
}
.client-profile-title {
  font-size: 22px;
  color: black;
  font-weight: 500;
}
.status-created {
  color: gray;
  font-weight: bold;
}

.status-needs-signature {
  color: red;
  font-weight: bold;
}

.status-signed {
  color: green;
  font-weight: bold;
}

.status-needs-loi {
  color: orange;
  font-weight: bold;
}
.icon-disabled {
  pointer-events: none;
  opacity: 0.4;
}
</style>
